import { Action, createReducer, on } from '@ngrx/store';
import { AuthState } from '@shared/store/auth/auth.state';
import {
  agreeToTermsAndConditions,
  agreeToTermsAndConditionsFailure,
  agreeToTermsAndConditionsSuccess,
  askForTermsAndConditionsAction,
  authErrorAction,
  checkLoginMailAction,
  checkLoginMailActionFailure,
  checkLoginMailActionSuccess,
  clearLastSignInCode,
  loginAction,
  loginActionFailure,
  loginActionSuccess,
  loginMagicLinkAction,
  loginMagicLinkActionFailure,
  loginMagicLinkActionSuccess,
  loginSuccessAction,
  loginWithTokenAction,
  passwordResetAction,
  passwordResetFailureAction,
  passwordResetSuccessAction,
  resetPasswordResetDoneAction,
  sendVerificationCodeAction,
  sendVerificationCodeErrorAction,
  sendVerificationCodeSuccessAction,
  setRedirectUrlAfterLoginAction,
} from './auth.action';

export enum SignInType {
  VERIFICATION_CODE = 'VERIFICATION_CODE',
  LONG_MAGIC_LINK = 'LONG_MAGIC_LINK',
  SHORT_MAGIC_LINK = 'SHORT_MAGIC_LINK',
}

const authInitialState: AuthState = {
  errors: null,
  loading: false,
  auth: {
    authErrorCode: null,
    email: null,
    lastSignInCode: null,
    lastSignInType: null,
    lastTimeVerificationCodeSent: null,
    isSendingVerificationCode: false,
    redirectUrl: null,
    doesEmailExist: null,
    isLoginFromParamsLoading: false,
  },
};

const reducer = createReducer(
  authInitialState,

  on(passwordResetAction, checkLoginMailAction, (state) => ({
    ...state,
    loading: true,
  })),

  on(loginWithTokenAction, (state, { redirectUrl }) => ({
    ...state,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: true,
      redirectUrl,
    },
  })),

  on(loginMagicLinkAction, (state, { verificationCode, redirectUrl }) => ({
    ...state,
    loading: true,
    auth: {
      ...state.auth,
      lastSignInType: verificationCode.length > 6 ? SignInType.LONG_MAGIC_LINK : SignInType.SHORT_MAGIC_LINK,
      isLoginFromParamsLoading: true,
      redirectUrl,
    },
  })),

  on(loginAction, (state) => ({
    ...state,
    loading: true,
    auth: {
      ...state.auth,
      lastSignInType: SignInType.VERIFICATION_CODE,
    },
  })),

  on(
    loginSuccessAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      errors: null,
      auth: {
        ...state.auth,
        isLoginFromParamsLoading: false,
      },
    }),
  ),

  on(passwordResetFailureAction, checkLoginMailActionFailure, loginActionFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: false,
    },
  })),

  on(loginMagicLinkActionFailure, (state, { error, code }) => ({
    ...state,
    loading: false,
    errors: error,
    auth: {
      ...state.auth,
      lastSignInCode: code.length > 6 ? null : code,
      isLoginFromParamsLoading: false,
      magicLinkFailed: true,
    },
  })),

  on(clearLastSignInCode, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      lastSignInCode: null,
    },
  })),

  on(checkLoginMailActionSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(agreeToTermsAndConditions, (state) => ({
    ...state,
    loading: true,
  })),

  on(agreeToTermsAndConditionsSuccess, (state) => ({
    ...state,
    loading: false,
  })),

  on(agreeToTermsAndConditionsFailure, (state) => ({
    ...state,
    loading: false,
  })),

  on(loginActionSuccess, loginMagicLinkActionSuccess, (state, { link }) => ({
    ...state,
    loading: false,
    auth: {
      ...state.auth,
      isLoginFromParamsLoading: false,
      redirectUrl: link ?? state.auth.redirectUrl,
    },
  })),

  on(
    askForTermsAndConditionsAction,
    (state): AuthState => ({
      ...state,
      loading: false,
      auth: {
        ...state.auth,
        isLoginFromParamsLoading: false,
      },
    }),
  ),

  on(passwordResetSuccessAction, (state) => ({
    ...state,
    loading: false,
    auth: {
      ...state.auth,
      isResetRequestDone: true,
    },
  })),

  on(resetPasswordResetDoneAction, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isResetRequestDone: false,
    },
  })),

  on(authErrorAction, (state, { authErrorCode }) => ({
    ...state,
    auth: {
      ...state.auth,
      authErrorCode,
    },
  })),

  on(
    checkLoginMailAction,
    loginMagicLinkAction,
    (state, { email }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        email,
        doesEmailExist: null,
      },
    }),
  ),

  on(
    checkLoginMailActionSuccess,
    (state, { doesEmailExist }): AuthState => ({
      ...state,
      auth: {
        ...state.auth,
        doesEmailExist: doesEmailExist ?? false,
        lastSignInType: null,
        lastSignInCode: null,
      },
    }),
  ),

  on(sendVerificationCodeAction, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: true,
      authErrorCode: null,
      lastSignInType: null,
    },
  })),

  on(sendVerificationCodeSuccessAction, (state) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: false,
      lastTimeVerificationCodeSent: new Date(),
    },
  })),

  on(sendVerificationCodeErrorAction, (state, { error }) => ({
    ...state,
    auth: {
      ...state.auth,
      isSendingVerificationCode: false,
    },
    error,
  })),

  on(setRedirectUrlAfterLoginAction, (state, { redirectUrl }) => ({
    ...state,
    auth: {
      ...state.auth,
      redirectUrl,
    },
  })),
);

export function authReducer(state: AuthState = authInitialState, action: Action): AuthState {
  return reducer(state, action);
}
