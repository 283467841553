// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_HelpCaseType.proto

export enum ENUM_HelpCaseType {
  HELP_CASE_TYPE_UNSPECIFIED = 'HELP_CASE_TYPE_UNSPECIFIED',
  HELP_CASE_TYPE_BORROW_MORE_MONEY = 'HELP_CASE_TYPE_BORROW_MORE_MONEY',
  HELP_CASE_TYPE_MOVE_HOME = 'HELP_CASE_TYPE_MOVE_HOME',
  HELP_CASE_TYPE_FIRST_TIME_BUYER = 'HELP_CASE_TYPE_FIRST_TIME_BUYER',
  HELP_CASE_TYPE_BUY_TO_LET = 'HELP_CASE_TYPE_BUY_TO_LET',
  HELP_CASE_TYPE_EXTRA_PAYMENT = 'HELP_CASE_TYPE_EXTRA_PAYMENT',
  HELP_CASE_TYPE_HELP_ME = 'HELP_CASE_TYPE_HELP_ME',
  HELP_CASE_TYPE_PROPERTY_ENQUIRES = 'HELP_CASE_TYPE_PROPERTY_ENQUIRES',
  HELP_CASE_TYPE_ADJUST_BALANCE = 'HELP_CASE_TYPE_ADJUST_BALANCE',
  HELP_CASE_TYPE_RESUME_MORTGAGE_CASE_CREATION = 'HELP_CASE_TYPE_RESUME_MORTGAGE_CASE_CREATION',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
