<div class="h-100 p-4 pt-5 color-dark auth-container">
  <div class="landing-logo-container">
    <a href="https://www.dashly.com/" target="_blank">
      @if (isMabDomain) {
        <img
          class="landing-logo mab"
          src="https://storage.googleapis.com/dashlyprod-company-logos/154/logo-1702149160.png" />
      } @else {
        <img class="landing-logo" src="assets/images/mortgage-monitor-blue.png" />
      }
    </a>
  </div>
  <div class="flex-column">
    <span class="landing-title"> Welcome to <span class="bold">Mortgage Monitor</span> </span>
    <span class="landing-subtitle">Please enter your email address to continue</span>
    <div class="inputs">
      <div class="landing-responsive-row">
        <shared-form-field-wrapper label="E-mail" [formControl]="emailForm">
          <shared-input-text [formControl]="emailForm" name="login-email" type="email" />
        </shared-form-field-wrapper>
      </div>
      <div class="login-button-container">
        <button
          pButton
          class="p-button-primary w-100"
          (click)="login()"
          [disabled]="emailForm.invalid"
          [loading]="loading$ | async">
          Continue
        </button>
      </div>
    </div>
  </div>

  <div class="footer">
    <span>Need an account?&nbsp;</span>
    <a [href]="signUpUrl" class="form-change-button">Create an account</a>
  </div>
</div>
