import { HTTP_CP_HelpCase, HTTP_CP_ListHelpCases_Response } from '@api-new/caseservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getHelpCases = createAction('[HELP-CASES] Get Help Cases');
export const getHelpCasesSuccess = createAction('[HELP-CASES] Get Help Cases Success', props<HTTP_CP_ListHelpCases_Response>());
export const getHelpCasesFailure = createAction('[HELP-CASES] Get Help Cases Failure', props<{ error: ErrorModel }>());

export const addHelpCase = createAction('[HELP-CASES] Add Smart Search Case', props<{ helpCase: HTTP_CP_HelpCase }>());

export const closeHelpCase = createAction('[HELP-CASES] Close Help Case', props<{ helpCaseId: string }>());

export const closeHelpCaseSuccess = createAction('[HELP-CASES] Close HelpCase Success', props<{ helpCaseId: string }>());

export const closeHelpCaseFailure = createAction('[HELP-CASES] Close Help Cases Failure', props<{ error: ErrorModel }>());
