import { HTTP_CP_ListSmartSearchDeals_Request, HTTP_CP_SmartSearchDeal } from '@api-new/mortgageservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getSmartSearchDeals = createAction(
  '[SMART-SEARCH-DEALS] Get Smart Search Deals',
  props<HTTP_CP_ListSmartSearchDeals_Request>(),
);
export const getSmartSearchDealsSuccess = createAction(
  '[SMART-SEARCH-DEALS] Get Smart Search Deals Success',
  props<{
    smartSearchDealMap: {
      smartSearchId: string;
      smartSearchDeals: HTTP_CP_SmartSearchDeal[];
      numberOfSmartSearchDeals?: number;
    }[];
  }>(),
);
export const getSmartSearchDealsFailure = createAction(
  '[SMART-SEARCH-DEALS] Get Smart Search Deals Failure',
  props<{ error: ErrorModel }>(),
);

export const getSmartSearchDealsSilently = createAction(
  '[SMART-SEARCH-DEALS] Get Smart Search Deals Silently',
  props<HTTP_CP_ListSmartSearchDeals_Request>(),
);
