import { ENUM_MortgageSavingMethod } from '@api-new/common';
import { HTTP_CP_MortgageComparisonSettings } from '@api-new/mortgageservice';
import { DEFAULT_COMPARISON_PERIOD } from '@shared/contants';

export const defaultMortgageSearch: HTTP_CP_MortgageComparisonSettings = {
  excludedLenderIds: [],
  savingMethod: ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
  preferredComparisonPeriod: DEFAULT_COMPARISON_PERIOD,
  id: null,
  mortgageId: null,
  minInitialPeriod: null,
  maxInitialPeriod: null,
  maxProductFees: null,
  maxInterestRate: null,
  maxInitialPayments: null,
  minimumSavingsForMortgageExpiryTypeEarly: null,
  showFixed: null,
  showStandard: null,
  showStepped: null,
  showDiscount: null,
  addFeesToLoan: null,
  noEarlyRepaymentCharge: null,
  portable: null,
  offsetOnly: null,
  distributionType: null,
  purpose: null,
};

export function fillDefaultMortgageSearch(newCriteria: HTTP_CP_MortgageComparisonSettings): HTTP_CP_MortgageComparisonSettings {
  const defaultCriteria: HTTP_CP_MortgageComparisonSettings = defaultMortgageSearch;
  if (newCriteria == null) {
    return defaultCriteria;
  }
  return {
    ...defaultCriteria,
    ...newCriteria,
    maxInitialPayments:
      newCriteria.maxInitialPayments != null ? Math.round(newCriteria.maxInitialPayments <= 0 ? 0 : newCriteria.maxInitialPayments) : null,
    savingMethod: newCriteria.savingMethod ?? ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
  };
}
