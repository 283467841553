import { HTTP_CP_Mortgage } from '@api-new/mortgageservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  closeMortgageSuccess,
  createMortgage,
  createMortgageFailure,
  createMortgageSuccess,
  getMortgages,
  getMortgagesFailure,
  getMortgagesSuccess,
  updateMortgageComparisonSettings,
  updateMortgageComparisonSettingsFailure,
  updateMortgageComparisonSettingsSuccess,
} from '@shared/store/mortgages/mortgages.actions';
import { fillDefaultMortgageSearch } from '@shared/store/mortgages/mortgages.defaults';
import { MortgagesState } from '@shared/store/mortgages/mortgages.state';

export const getMortgageById = (mortgage: Pick<HTTP_CP_Mortgage, 'id'>): string => mortgage.id;

export const mortgagesAdapter: EntityAdapter<HTTP_CP_Mortgage> = createEntityAdapter<HTTP_CP_Mortgage>({
  selectId: getMortgageById,
});

export const mortgagesInitialState: MortgagesState = mortgagesAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  mortgagesInitialState,

  on(getMortgages, (state) => ({
    ...state,
    loading: true,
  })),

  on(getMortgagesSuccess, (state, { mortgages }) =>
    mortgagesAdapter.setAll(mortgages ? mortgages : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getMortgagesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(createMortgage, (state) => ({ ...state, loading: true })),

  on(createMortgageSuccess, (state, { mortgage }) => mortgagesAdapter.addOne(mortgage, { ...state, loading: false })),

  on(createMortgageFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(closeMortgageSuccess, (state, { mortgageId }) => mortgagesAdapter.removeOne(mortgageId, { ...state, loading: false })),

  on(updateMortgageComparisonSettings, (state) => ({ ...state, loading: true })),

  on(updateMortgageComparisonSettingsSuccess, (state, { comparisonSettings }) => {
    const entities: Record<string, HTTP_CP_Mortgage> = {
      ...state.entities,
      [comparisonSettings.mortgageId]: {
        ...state.entities[comparisonSettings.mortgageId],
        mortgageComparisonSettings: fillDefaultMortgageSearch(comparisonSettings),
      },
    };
    return {
      ...state,
      entities,
      loading: false,
    };
  }),

  on(updateMortgageComparisonSettingsFailure, (state, { error }) => ({ ...state, loading: false, error })),
);

export function mortgagesReducer(state: MortgagesState = mortgagesInitialState, action: Action): MortgagesState {
  return reducer(state, action);
}
