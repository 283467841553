import { HTTP_CP_ListSmartSearches_Response, HTTP_CP_SmartSearch } from '@api-new/mortgageservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getSmartSearches = createAction('[SMART-SEARCHES] Get Smart Searches');
export const getSmartSearchesSuccess = createAction(
  '[SMART-SEARCHES] Get Smart Searches Success',
  props<HTTP_CP_ListSmartSearches_Response>(),
);
export const getSmartSearchesFailure = createAction('[SMART-SEARCHES] Get Smart Searches Failure', props<{ error: ErrorModel }>());

export const addSmartSearch = createAction('[SMART-SEARCHES] Add Smart Search Success', props<{ smartSearch: HTTP_CP_SmartSearch }>());

export const updateSmartSearch = createAction(
  '[SMART-SEARCHES] Update Smart Search Success',
  props<{ smartSearch: HTTP_CP_SmartSearch }>(),
);
