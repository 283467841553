import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CheckboxComponent } from '@shared/components/checkbox/checkbox.component';
import { ButtonDirective } from 'primeng/button';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-terms-and-conditions-agreement',
  templateUrl: './terms-and-conditions-agreement.component.html',
  styleUrls: ['./terms-and-conditions-agreement.component.scss', '../../auth.component.scss'],
  standalone: true,
  imports: [AsyncPipe, CheckboxComponent, ReactiveFormsModule, ButtonDirective],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsAgreementComponent {
  readonly loading$ = this.facade.loading$;

  readonly privacyPolicyLink = 'https://www.dashly.com/terms/privacy';
  readonly tacLink = 'https://www.dashly.com/terms';
  acceptedTermsAndConditionsForm: UntypedFormGroup;

  constructor(
    private readonly facade: AuthFacadeService,
    private readonly fb: UntypedFormBuilder,
  ) {
    this.acceptedTermsAndConditionsForm = this.fb.group({
      agreeTerms: [null, [Validators.requiredTrue]],
    });
  }

  agreeAndLogin(): void {
    if (this.acceptedTermsAndConditionsForm.invalid) {
      return;
    }
    this.facade.agreeToTermsAndConditions();
  }

  showWarnState(): boolean {
    const control = this.acceptedTermsAndConditionsForm.controls.agreeTerms;
    return control.touched && control.invalid;
  }
}
