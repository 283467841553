import { Component, Input } from '@angular/core';
import { FormControl, FormGroupDirective, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'shared-validation-message',
  templateUrl: './validation-message.component.html',
  styleUrls: ['./validation-message.component.scss'],
  standalone: true,
  imports: [],
  providers: [FormGroupDirective],
})
export class ValidationMessageComponent {
  @Input() control: FormControl | undefined;
  @Input() reactNoSubmitted = false;

  get errors(): ValidationErrors | null | undefined {
    return this.control?.errors;
  }
}
