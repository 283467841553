import {
  ENUM_HelpCaseType,
  ENUM_MortgagePartStepType,
  ENUM_MortgageRepaymentType,
  ENUM_SmartSearchRemortgageReason,
} from '@api-new/common';
import { paths } from '@platform/paths';
import { SelectItem } from 'primeng/api';

export const HELP_LINK = 'https://help.dashly.com';

export const PAGES_WITH_MOBILE_HEADER_AND_MENU_VISIBLE = [
  `/${paths.PLATFORM}/${paths.platform.DASHBOARD}`,
  `/${paths.PLATFORM}/${paths.platform.PROPERTIES}`,
  `/${paths.PLATFORM}/${paths.platform.DOCUMENTS}`,
];

export const smartSearchBorrowMoreReasons: SelectItem<ENUM_SmartSearchRemortgageReason>[] = [
  {
    value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_ADD_OR_REMOVE_PERSON,
    label: 'Add/remove person from the property',
  },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_CONSOLIDATE_DEBT, label: 'Consolidate debt' },
  {
    value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_PURCHASE_ADDITIONAL_EQUITY,
    label: 'Purchase additional equity in my home',
  },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_EXTEND_LEASE, label: 'Extend the lease on my home' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_HOME_IMPROVEMENT, label: 'Home improvement' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_DIVORCE_SETTLEMENT, label: 'Divorce settlement' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_SCHOOL_FEES, label: 'School fees' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_BUYING_ANOTHER_PROPERTY, label: 'Buying another property' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_TAX_BILL_OR_FEES, label: 'Tax bill/other fees' },
  { value: ENUM_SmartSearchRemortgageReason.SMART_SEARCH_REMORTGAGE_REASON_OTHER, label: 'Other' },
];

export const helpCaseTypeSelectItems: SelectItem<ENUM_HelpCaseType>[] = [
  {
    label: 'Borrow more money',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_BORROW_MORE_MONEY,
  },
  {
    label: 'Adjust balance',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_ADJUST_BALANCE,
  },
  {
    label: 'Move home',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_MOVE_HOME,
  },
  {
    label: 'First time buyer',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_FIRST_TIME_BUYER,
  },
  {
    label: 'Buy to let',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_BUY_TO_LET,
  },
  {
    label: 'Extra payment',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_EXTRA_PAYMENT,
  },
  {
    label: 'Help me',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_HELP_ME,
  },
  {
    label: 'Property enquires',
    value: ENUM_HelpCaseType.HELP_CASE_TYPE_PROPERTY_ENQUIRES,
  },
];

export const repaymentTypeSelectItems: SelectItem<ENUM_MortgageRepaymentType>[] = [
  {
    label: 'Full repayment',
    value: ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_FULL_REPAYMENT,
  },
  {
    label: 'Interest only',
    value: ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_INTEREST_ONLY,
  },
];

export const mortgageInterestRateSelectItems: SelectItem<ENUM_MortgagePartStepType>[] = [
  {
    label: 'Fixed',
    value: ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED,
  },
  {
    label: 'Variable',
    value: ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE,
  },
];

export const UK_PHONE_NUMBER_REGEX =
  '^(((\\+44\\s?\\d{4}|\\(?0\\d{4}\\)?)\\s?\\d{3}\\s?\\d{3})|((\\+44\\s?\\d{3}|\\(?0\\d{3}\\)?)\\s?\\d{3}\\s?\\d{4})|((\\+44\\s?\\d{2}|\\(?0\\d{2}\\)?)\\s?\\d{4}\\s?\\d{4}))(\\s?\\#(\\d{4}|\\d{3}))?$'; // tslint:disable:max-line-length

export const DASHLY_COMPANY_ID = '1';

export const MONTHS_IN_YEAR = 12;

export const DEFAULT_INITIAL_PERIOD = 24;
export const DEFAULT_COMPARISON_PERIOD = 24; // months

export const INTEREST_RATE_THRESHOLDS = {
  absoluteMin: 0.001,
  warningMin: 0.004,
  warningMax: 0.07,
  absoluteMax: 0.15,
};
