import {
  HTTP_CP_CreateProperty_Request,
  HTTP_CP_ListProperties_Response,
  HTTP_CP_Property,
  HTTP_CP_PropertyValue,
  HTTP_CP_SetPropertyValue_Request,
  HTTP_CP_SetPropertyValue_Response,
  HTTP_CP_UnsetPropertyValue_Response,
  HTTP_CP_UpdateProperty_Request,
} from '@api-new/propertyservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getProperties = createAction('[PROPERTIES] Get Properties');
export const getPropertiesSuccess = createAction('[PROPERTIES] Get Properties Success', props<HTTP_CP_ListProperties_Response>());
export const getPropertiesFailure = createAction('[PROPERTIES] Get Properties Failure', props<{ error: ErrorModel }>());

export const addProperty = createAction('[PROPERTIES] Add Property', props<{ property: HTTP_CP_CreateProperty_Request }>());
export const addPropertySuccess = createAction('[PROPERTIES] Add Property Success', props<{ property: HTTP_CP_Property }>());
export const addPropertyFailure = createAction('[PROPERTIES] Add Property Failure', props<{ error: ErrorModel }>());

export const updateProperty = createAction('[PROPERTIES] Update Property', props<{ property: HTTP_CP_UpdateProperty_Request }>());
export const updatePropertySuccess = createAction('[PROPERTIES] Update Property Success', props<{ property: HTTP_CP_Property }>());
export const updatePropertyFailure = createAction('[PROPERTIES] Update Property Failure', props<{ error: ErrorModel }>());

export const updatePropertyValuation = createAction(
  '[PROPERTIES] Update Property Valuation',
  props<{ propertyValuation: HTTP_CP_SetPropertyValue_Request }>(),
);
export const updatePropertyValuationSuccess = createAction(
  '[PROPERTIES] Update Property Valuation Success',
  props<{ propertyId: string; propertyValuation: HTTP_CP_SetPropertyValue_Response }>(),
);
export const updatePropertyValuationFailure = createAction(
  '[PROPERTIES] Update Property Valuation Failure',
  props<{ error: ErrorModel }>(),
);

export const deletePropertyValue = createAction('[PROPERTIES] Delete Property Value', props<{ propertyValue: HTTP_CP_PropertyValue }>());
export const deletePropertyValueSuccess = createAction(
  '[PROPERTIES] Delete Property Value Success',
  props<{ propertyId: string; propertyValuation: HTTP_CP_UnsetPropertyValue_Response }>(),
);
export const deletePropertyValueFailure = createAction('[PROPERTIES] Delete Property Value Failure', props<{ error: ErrorModel }>());

export const closeProperty = createAction('[PROPERTIES] Close Property', props<{ propertyId: string; closedDate: Date }>());
export const closePropertySuccess = createAction('[PROPERTIES] Close Property Success', props<{ propertyId: string }>());
export const closePropertyFailure = createAction('[PROPERTIES] Close Property Failure', props<{ error: ErrorModel }>());

export const closePropertyWithMortgageSuccess = createAction(
  '[PROPERTIES] Close Property With Mortgage Success',
  props<{ propertyId: string; mortgageId: string }>(),
);
