// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_SmartSearchRemortgageReason.proto

export enum ENUM_SmartSearchRemortgageReason {
  SMART_SEARCH_REMORTGAGE_REASON_UNSPECIFIED = 'SMART_SEARCH_REMORTGAGE_REASON_UNSPECIFIED',
  SMART_SEARCH_REMORTGAGE_REASON_ADD_OR_REMOVE_PERSON = 'SMART_SEARCH_REMORTGAGE_REASON_ADD_OR_REMOVE_PERSON',
  SMART_SEARCH_REMORTGAGE_REASON_CONSOLIDATE_DEBT = 'SMART_SEARCH_REMORTGAGE_REASON_CONSOLIDATE_DEBT',
  SMART_SEARCH_REMORTGAGE_REASON_PURCHASE_ADDITIONAL_EQUITY = 'SMART_SEARCH_REMORTGAGE_REASON_PURCHASE_ADDITIONAL_EQUITY',
  SMART_SEARCH_REMORTGAGE_REASON_EXTEND_LEASE = 'SMART_SEARCH_REMORTGAGE_REASON_EXTEND_LEASE',
  SMART_SEARCH_REMORTGAGE_REASON_HOME_IMPROVEMENT = 'SMART_SEARCH_REMORTGAGE_REASON_HOME_IMPROVEMENT',
  SMART_SEARCH_REMORTGAGE_REASON_DIVORCE_SETTLEMENT = 'SMART_SEARCH_REMORTGAGE_REASON_DIVORCE_SETTLEMENT',
  SMART_SEARCH_REMORTGAGE_REASON_SCHOOL_FEES = 'SMART_SEARCH_REMORTGAGE_REASON_SCHOOL_FEES',
  SMART_SEARCH_REMORTGAGE_REASON_BUYING_ANOTHER_PROPERTY = 'SMART_SEARCH_REMORTGAGE_REASON_BUYING_ANOTHER_PROPERTY',
  SMART_SEARCH_REMORTGAGE_REASON_TAX_BILL_OR_FEES = 'SMART_SEARCH_REMORTGAGE_REASON_TAX_BILL_OR_FEES',
  SMART_SEARCH_REMORTGAGE_REASON_OTHER = 'SMART_SEARCH_REMORTGAGE_REASON_OTHER',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
