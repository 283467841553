import { HTTP_CP_ListSmartSearchCases_Response, HTTP_CP_SmartSearchCase } from '@api-new/caseservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getSmartSearchCases = createAction('[SMART-SEARCH-CASES] Get Smart Search Cases');
export const getSmartSearchCasesSuccess = createAction(
  '[SMART-SEARCH-CASES] Get Smart Search Cases Success',
  props<HTTP_CP_ListSmartSearchCases_Response>(),
);
export const getSmartSearchCasesFailure = createAction(
  '[SMART-SEARCH-CASES] Get Smart Search Cases Failure',
  props<{ error: ErrorModel }>(),
);

export const addSmartSearchCase = createAction(
  '[SMART-SEARCH-CASES] Add Smart Search Case',
  props<{ smartSearchCase: HTTP_CP_SmartSearchCase }>(),
);

export const removeSmartSearchCase = createAction('[SMART-SEARCH-CASES] Remove Smart Search Case', props<{ smartSearchCaseId: string }>());
