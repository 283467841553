import { Injectable } from '@angular/core';
import { CompanyApiService } from '@api-new/companyservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getCompanyByIDAction, getCompanyFailureAction, getCompanySuccessAction } from './company.actions';

@Injectable()
export class CompanyEffects {
  getCompanyById = createEffect(() =>
    this.actions$.pipe(
      ofType(getCompanyByIDAction),
      switchMap(({ id }) => this.companyApiService.HTTP_CP_GetCompany({ companyId: id })),
      map((company) => getCompanySuccessAction({ company })),
      catchError((error) => of(getCompanyFailureAction({ error }))),
    ),
  );

  constructor(
    private readonly companyApiService: CompanyApiService,
    private readonly actions$: Actions,
  ) {}
}
