import { HTTP_CP_ListMortgageDeals_Request, HTTP_CP_MortgageDeal } from '@api-new/mortgageservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const setSelectedBetterDeal = createAction(
  '[BETTER-DEALS] Set Selected Better Deal',
  props<{ deal: HTTP_CP_MortgageDeal; selectedBetterDealIndex: number }>(),
);

export const getBetterDeals = createAction('[BETTER-DEALS] Get Better Deals', props<HTTP_CP_ListMortgageDeals_Request>());

export const getBetterDealsSuccess = createAction(
  '[BETTER-DEALS] Get Better Deals Success',
  props<{ mortgageId: string; mortgageDeals: HTTP_CP_MortgageDeal[] }>(),
);
export const getBetterDealsFailure = createAction('[BETTER-DEALS] Get Better Deals Failure', props<{ error: ErrorModel }>());

export const getBetterDeal = createAction('[BETTER-DEALS] Get Better Deal', props<HTTP_CP_ListMortgageDeals_Request>());

export const betterDealNotFound = createAction('[BETTER-DEALS] Better Deal Not Found');

export const getBetterDealFailure = createAction('[BETTER-DEALS] Get Better Deal Failure', props<{ error: ErrorModel }>());

export const clearSelectedBetterDeal = createAction('[BETTER-DEALS] Clear Selected Better Deal');
