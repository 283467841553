import { Component, Input, Optional, Self } from '@angular/core';
import { FormGroupDirective, NgControl, ReactiveFormsModule } from '@angular/forms';
import { AbstractControlToFormControlPipe } from '../../../pipes/abstract-control-to-form-control.pipe';
import { ValidationMessageComponent } from '../validation-message/validation-message.component';

@Component({
  selector: 'shared-form-field-wrapper',
  standalone: true,
  imports: [ValidationMessageComponent, ReactiveFormsModule, AbstractControlToFormControlPipe],
  templateUrl: './form-field-wrapper.component.html',
  styleUrls: ['./form-field-wrapper.component.scss'],
  providers: [FormGroupDirective],
})
export class FormFieldWrapperComponent {
  @Input() label: string | undefined;
  @Input() reactNoSubmitted = false;
  @Input() name: string | undefined;

  constructor(@Self() @Optional() public ngControl: NgControl) {
    if (this.ngControl) {
      // trick it into thinking control has a valueAccessor, we don't need it because child has
      this.ngControl.valueAccessor = {
        writeValue(): void {
          // do nothing
        },
        registerOnChange(): void {
          // do nothing
        },
        registerOnTouched(): void {
          // do nothing
        },
      };
    }
  }
}
