<div
  class="checkbox-container"
  [ngClass]="{
    disabled: disabled,
  }"
  tabindex="0">
  <div class="checkbox-wrapper" (click)="checkboxClicked()" (keydown.enter)="checkboxClicked()">
    <div class="checkbox" [ngClass]="{ checked: checked }">
      @if (checked) {
        <img src="assets/icons/checkmark.svg" />
      }
    </div>
  </div>

  @if (label) {
    <span class="checkbox-label" (click)="checkboxClicked()" (keydown.enter)="checkboxClicked()">
      {{ label }}
    </span>
  }
</div>
