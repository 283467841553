<label [for]="name">{{ label }}</label>
<ng-content />
<div class="validation-messages">
  <div class="custom-validation-message" #customValidationMessage>
    <ng-content select="[validation]" />
  </div>

  @if (ngControl && !customValidationMessage.hasChildNodes()) {
    <shared-validation-message [control]="ngControl.control | abstractControlToFormControl" />
  }
</div>
