import { Action, createReducer, on } from '@ngrx/store';
import { CompanyState } from '@shared/store/company/company.state';
import { getCompanyByIDAction, getCompanyFailureAction, getCompanySuccessAction } from './company.actions';

export const companyInitialState: CompanyState = {
  loading: false,
  company: null,
  errors: null,
};

const reducer = createReducer(
  companyInitialState,

  on(getCompanyByIDAction, (state) => ({
    ...state,
    loading: false,
  })),

  on(getCompanySuccessAction, (state, { company }) => ({
    ...state,
    loading: false,
    company,
  })),

  on(getCompanyFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function companyReducer(state: CompanyState = companyInitialState, action: Action): CompanyState {
  return reducer(state, action);
}
