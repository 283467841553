import { HTTP_CP_ClientUser } from '@api-new/userservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '@shared/models/app-state.model';

export const getClientUser = createAction('[CLIENT-USER] Get User');
export const getClientUserSuccess = createAction('[CLIENT-USER] Get User Success', props<{ clientUser: HTTP_CP_ClientUser }>());
export const getClientUserFailure = createAction('[CLIENT-USER] Get User Failure', props<{ error: ErrorModel }>());

export const updateClientUser = createAction('[CLIENT-USER] Update User', props<{ clientUser: HTTP_CP_ClientUser }>());
export const updateClientUserSuccess = createAction('[CLIENT-USER] Update User Success', props<{ clientUser: HTTP_CP_ClientUser }>());
export const updateClientUserFailure = createAction('[CLIENT-USER] Update User Failure', props<{ error: ErrorModel }>());

export const updateClientUserSilently = createAction('[CLIENT-USER] Update User Silently', props<{ clientUser: HTTP_CP_ClientUser }>());

export const clearClientUser = createAction('[CLIENT-USER] Clear User');

export const provideClientUserDeleteCode = createAction('[CLIENT-USER] Provide User Delete Code');
export const provideClientUserDeleteCodeSuccess = createAction('[CLIENT-USER] Provide User Delete Code Success');
export const provideClientUserDeleteCodeFailure = createAction(
  '[CLIENT-USER] Provide User Delete Code Failure',
  props<{ error: ErrorModel }>(),
);

export const deleteClientUser = createAction('[CLIENT-USER] Delete User', props<{ code: string }>());
export const deleteClientUserSuccess = createAction('[CLIENT-USER] Delete User Success');
export const deleteClientUserFailure = createAction('[CLIENT-USER] Delete User Failure', props<{ error: ErrorModel }>());

export const dismissClientUserLandlordPropertyBanner = createAction('[CLIENT-USER] Dismiss Landlord Property Banner');
export const dismissClientUserLandlordPropertyBannerSuccess = createAction(
  '[CLIENT-USER] Dismiss Landlord Property Banner Success',
  props<{ clientUser: HTTP_CP_ClientUser }>(),
);
export const dismissClientUserLandlordPropertyBannerFailure = createAction(
  '[CLIENT-USER] Dismiss Landlord Property Banner Failure',
  props<{ error: ErrorModel }>(),
);
