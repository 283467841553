import { HTTP_CP_Company } from '@api-new/companyservice';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyState } from '@shared/store/company/company.state';

export const $companyState = createFeatureSelector<CompanyState>('company');

export const $company = createSelector($companyState, (state: CompanyState): HTTP_CP_Company | null => state.company || null);

export const $companyLogo = createSelector($company, (company: HTTP_CP_Company): string | null => company?.logo || null);

export const $companyTradingName = createSelector($company, (company: HTTP_CP_Company): string | null => company?.tradingName || null);

export const $companyFooter = createSelector($company, (company: HTTP_CP_Company): string => company?.footer ?? '');

export const $companyShowDashboardAdvisorCard = createSelector(
  $company,
  (company: HTTP_CP_Company): boolean => company?.showDashboardAdvisorCard,
);
