import moment, { Moment } from 'moment';

export const dateToISOStringWithoutTimezone = (inputDate: Date | Moment | string | null | undefined): string | null => {
  let actualDate: Date;

  if (inputDate == null) return null;

  if (inputDate instanceof Date) {
    actualDate = inputDate;
  } else if (typeof inputDate === 'object') {
    actualDate = moment(inputDate).toDate();
  } else {
    actualDate = new Date(inputDate);
  }

  return new Date(Date.UTC(actualDate.getFullYear(), actualDate.getMonth(), actualDate.getDate())).toISOString();
};
