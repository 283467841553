<div class="h-100 p-4 pt-5 color-dark auth-container">
  <form
    [formGroup]="acceptedTermsAndConditionsForm"
    (ngSubmit)="agreeAndLogin()"
    #ngForm="ngForm"
    class="flex-column gap-5">
    <span class="text-center text-28 bold-800">Welcome to your new Mortgage Monitor</span>
    <span class="text-18 text-center line-height-130">
      As part of our ongoing commitment to providing you with expert advice, we monitor your
      mortgage 24/7 to ensure that you’re always on the deal that’s best suited to your
      circumstances.
    </span>
    <div class="flex-column gap-3">
      <div class="third-party-getting-started-item">
        <div class="third-party-getting-started-item-icon-container">
          <img
            class="third-party-getting-started-item-icon"
            src="../../../../assets/icons/third-party-getting-started-equity.svg" />
          <img
            class="third-party-getting-started-item-checkmark"
            src="../../../../assets/icons/third-party-getting-started-checkmark.svg" />
        </div>
        <span class="text-16 line-height-150">
          <span class="bold">Track the equity</span>
          that's building up in your property and send you monthly reports
        </span>
      </div>
      <div class="third-party-getting-started-item">
        <div class="third-party-getting-started-item-icon-container">
          <img
            class="third-party-getting-started-item-icon"
            src="../../../../assets/icons/third-party-getting-started-alerts.svg" />
          <img
            class="third-party-getting-started-item-checkmark"
            src="../../../../assets/icons/third-party-getting-started-checkmark.svg" />
        </div>
        <span class="text-16 line-height-150">
          <span class="bold">Notify you</span>
          the moment there is a better rate your rate changes or comes to an end
        </span>
      </div>
      <div class="third-party-getting-started-item">
        <div class="third-party-getting-started-item-icon-container">
          <img
            class="third-party-getting-started-item-icon"
            src="../../../../assets/icons/third-party-getting-started-documents.svg" />
          <img
            class="third-party-getting-started-item-checkmark"
            src="../../../../assets/icons/third-party-getting-started-checkmark.svg" />
        </div>
        <span class="text-16 line-height-150">
          <span class="bold">Challenge your</span>
          current mortgage deal against thousands of other products
        </span>
      </div>
    </div>

    <div>
      <div class="flex gap-2 align-items-center">
        <cp-checkbox formControlName="agreeTerms" />
        <span class="terms-and-conditions-label">
          I agree to
          <a class="underline" target="_blank" [href]="tacLink">Terms of Use</a>
          and
          <a class="underline" target="_blank" [href]="privacyPolicyLink">Privacy Policy</a>
        </span>
      </div>
      <div class="text-14 color-orange mt-1">
        @if (showWarnState()) {
          You have to agree with Terms of Use and Privacy Policy
        }
      </div>
    </div>

    <div class="align-self-center">
      <button
        pButton
        class="p-button p-button-primary"
        (click)="ngForm.onSubmit($event)"
        [disabled]="acceptedTermsAndConditionsForm.invalid"
        [loading]="loading$ | async">
        Continue
      </button>
    </div>
  </form>
</div>
