import { Injectable } from '@angular/core';
import { HTTP_AP_CloseProperty_Request } from '@api-new/propertyservice/HTTP_AP_CloseProperty_Request';
import { HTTP_AP_CompanyPropertySettings } from '@api-new/propertyservice/HTTP_AP_CompanyPropertySettings';
import { HTTP_AP_CreateHPIOutcodeToRegionEntry_Request } from '@api-new/propertyservice/HTTP_AP_CreateHPIOutcodeToRegionEntry_Request';
import { HTTP_AP_CreateProperty_Request } from '@api-new/propertyservice/HTTP_AP_CreateProperty_Request';
import { HTTP_AP_DeleteHPIOutcodeToRegionEntry_Request } from '@api-new/propertyservice/HTTP_AP_DeleteHPIOutcodeToRegionEntry_Request';
import { HTTP_AP_GetCompanyPropertySettings_Request } from '@api-new/propertyservice/HTTP_AP_GetCompanyPropertySettings_Request';
import { HTTP_AP_GetPropertyCard_Request } from '@api-new/propertyservice/HTTP_AP_GetPropertyCard_Request';
import { HTTP_AP_GetPropertyCard_Response } from '@api-new/propertyservice/HTTP_AP_GetPropertyCard_Response';
import { HTTP_AP_GetPropertyEquityGraph_Request } from '@api-new/propertyservice/HTTP_AP_GetPropertyEquityGraph_Request';
import { HTTP_AP_GetPropertyEquityGraph_Response } from '@api-new/propertyservice/HTTP_AP_GetPropertyEquityGraph_Response';
import { HTTP_AP_GetPropertyEquity_ForSummary_Request } from '@api-new/propertyservice/HTTP_AP_GetPropertyEquity_ForSummary_Request';
import { HTTP_AP_GetPropertyEquity_ForSummary_Response } from '@api-new/propertyservice/HTTP_AP_GetPropertyEquity_ForSummary_Response';
import { HTTP_AP_GetProperty_ForClientDetail_Request } from '@api-new/propertyservice/HTTP_AP_GetProperty_ForClientDetail_Request';
import { HTTP_AP_GetProperty_ForClientDetail_Response } from '@api-new/propertyservice/HTTP_AP_GetProperty_ForClientDetail_Response';
import { HTTP_AP_GetResidentialPropertyCard_Request } from '@api-new/propertyservice/HTTP_AP_GetResidentialPropertyCard_Request';
import { HTTP_AP_GetResidentialPropertyCard_Response } from '@api-new/propertyservice/HTTP_AP_GetResidentialPropertyCard_Response';
import { HTTP_AP_HPIOutcodeToRegionEntry } from '@api-new/propertyservice/HTTP_AP_HPIOutcodeToRegionEntry';
import { HTTP_AP_ListHPIIssues_Response } from '@api-new/propertyservice/HTTP_AP_ListHPIIssues_Response';
import { HTTP_AP_ListHPIOutcodeToRegionEntries_Request } from '@api-new/propertyservice/HTTP_AP_ListHPIOutcodeToRegionEntries_Request';
import { HTTP_AP_ListHPIOutcodeToRegionEntries_Response } from '@api-new/propertyservice/HTTP_AP_ListHPIOutcodeToRegionEntries_Response';
import { HTTP_AP_ListProperties_ForClientDetailMortgageList_Request } from '@api-new/propertyservice/HTTP_AP_ListProperties_ForClientDetailMortgageList_Request';
import { HTTP_AP_ListProperties_ForClientDetailMortgageList_Response } from '@api-new/propertyservice/HTTP_AP_ListProperties_ForClientDetailMortgageList_Response';
import { HTTP_AP_ListProperties_ForTable_Request } from '@api-new/propertyservice/HTTP_AP_ListProperties_ForTable_Request';
import { HTTP_AP_ListProperties_ForTable_Response } from '@api-new/propertyservice/HTTP_AP_ListProperties_ForTable_Response';
import { HTTP_AP_ListPropertyCards_Request } from '@api-new/propertyservice/HTTP_AP_ListPropertyCards_Request';
import { HTTP_AP_ListPropertyCards_Response } from '@api-new/propertyservice/HTTP_AP_ListPropertyCards_Response';
import { HTTP_AP_ListPropertyComparables_Request } from '@api-new/propertyservice/HTTP_AP_ListPropertyComparables_Request';
import { HTTP_AP_ListPropertyComparables_Response } from '@api-new/propertyservice/HTTP_AP_ListPropertyComparables_Response';
import { HTTP_AP_Property } from '@api-new/propertyservice/HTTP_AP_Property';
import { HTTP_AP_SetPropertyValue_Request } from '@api-new/propertyservice/HTTP_AP_SetPropertyValue_Request';
import { HTTP_AP_UnsetPropertyValue_Request } from '@api-new/propertyservice/HTTP_AP_UnsetPropertyValue_Request';
import { HTTP_AP_UpdateCompanyPropertySettings_Request } from '@api-new/propertyservice/HTTP_AP_UpdateCompanyPropertySettings_Request';
import { HTTP_AP_UpdateHPIOutcodeToRegionEntry_Request } from '@api-new/propertyservice/HTTP_AP_UpdateHPIOutcodeToRegionEntry_Request';
import { HTTP_AP_UpdatePropertyName_Request } from '@api-new/propertyservice/HTTP_AP_UpdatePropertyName_Request';
import { HTTP_AP_UpdatePropertyName_Response } from '@api-new/propertyservice/HTTP_AP_UpdatePropertyName_Response';
import { HTTP_AP_UpdateProperty_FromClientDetail_Request } from '@api-new/propertyservice/HTTP_AP_UpdateProperty_FromClientDetail_Request';
import { HTTP_CP_CloseProperty_Request } from '@api-new/propertyservice/HTTP_CP_CloseProperty_Request';
import { HTTP_CP_CreateProperty_Request } from '@api-new/propertyservice/HTTP_CP_CreateProperty_Request';
import { HTTP_CP_ListProperties_Response } from '@api-new/propertyservice/HTTP_CP_ListProperties_Response';
import { HTTP_CP_Property } from '@api-new/propertyservice/HTTP_CP_Property';
import { HTTP_CP_SetPropertyValue_Request } from '@api-new/propertyservice/HTTP_CP_SetPropertyValue_Request';
import { HTTP_CP_SetPropertyValue_Response } from '@api-new/propertyservice/HTTP_CP_SetPropertyValue_Response';
import { HTTP_CP_UnsetPropertyValue_Request } from '@api-new/propertyservice/HTTP_CP_UnsetPropertyValue_Request';
import { HTTP_CP_UnsetPropertyValue_Response } from '@api-new/propertyservice/HTTP_CP_UnsetPropertyValue_Response';
import { HTTP_CP_UpdateProperty_Request } from '@api-new/propertyservice/HTTP_CP_UpdateProperty_Request';
import { HTTP_X_FindAddress_LandClan_Request } from '@api-new/propertyservice/HTTP_X_FindAddress_LandClan_Request';
import { HTTP_X_FindAddress_LandClan_Response } from '@api-new/propertyservice/HTTP_X_FindAddress_LandClan_Response';
import { HTTP_X_FindAddress_Request } from '@api-new/propertyservice/HTTP_X_FindAddress_Request';
import { HTTP_X_FindAddress_Response } from '@api-new/propertyservice/HTTP_X_FindAddress_Response';
import { HTTP_X_RetrieveAddress_LandClan_Request } from '@api-new/propertyservice/HTTP_X_RetrieveAddress_LandClan_Request';
import { HTTP_X_RetrieveAddress_LandClan_Response } from '@api-new/propertyservice/HTTP_X_RetrieveAddress_LandClan_Response';
import { HTTP_X_RetrieveAddress_Request } from '@api-new/propertyservice/HTTP_X_RetrieveAddress_Request';
import { HTTP_X_RetrieveAddress_Response } from '@api-new/propertyservice/HTTP_X_RetrieveAddress_Response';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class PropertyApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_CloseProperty(request: HTTP_AP_CloseProperty_Request): Observable<undefined> {
    return this.httpClient.post(`/property-service/ap/properties/${request.propertyId}/Close`, request);
  }

  HTTP_AP_CreateHPIOutcodeToRegionEntry(
    request: HTTP_AP_CreateHPIOutcodeToRegionEntry_Request,
  ): Observable<HTTP_AP_HPIOutcodeToRegionEntry> {
    return this.httpClient.post(`/property-service/ap/hpi/outcode-to-region-entries`, request);
  }

  HTTP_AP_CreateProperty(request: HTTP_AP_CreateProperty_Request): Observable<HTTP_AP_Property> {
    // TODO AP Response object?

    return this.httpClient.post(`/property-service/ap/properties`, request);
  }

  HTTP_AP_DeleteHPIOutcodeToRegionEntry(request: HTTP_AP_DeleteHPIOutcodeToRegionEntry_Request): Observable<undefined> {
    return this.httpClient.delete(`/property-service/ap/hpi/outcode-to-region-entries`, { params: request });
  }

  HTTP_AP_GetCompanyPropertySettings(request: HTTP_AP_GetCompanyPropertySettings_Request): Observable<HTTP_AP_CompanyPropertySettings> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/property-service/ap/company-property-settings/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_AP_GetProperty_ForClientDetail(
    request: HTTP_AP_GetProperty_ForClientDetail_Request,
  ): Observable<HTTP_AP_GetProperty_ForClientDetail_Response> {
    const { propertyId, ...updatedRequest } = request;
    return this.httpClient.get(`/property-service/ap/properties/${request.propertyId}/ForClientDetail`, { params: updatedRequest });
  }

  HTTP_AP_GetPropertyCard(request: HTTP_AP_GetPropertyCard_Request): Observable<HTTP_AP_GetPropertyCard_Response> {
    return this.httpClient.get(`/property-service/ap/properties/one/PropertyCard`, { params: request });
  }

  HTTP_AP_GetPropertyEquity_ForSummary(
    request: HTTP_AP_GetPropertyEquity_ForSummary_Request,
  ): Observable<HTTP_AP_GetPropertyEquity_ForSummary_Response> {
    return this.httpClient.get(`/property-service/ap/properties/ForSummary`, { params: request });
  }

  HTTP_AP_GetPropertyEquityGraph(request: HTTP_AP_GetPropertyEquityGraph_Request): Observable<HTTP_AP_GetPropertyEquityGraph_Response> {
    return this.httpClient.get(`/property-service/ap/properties/EquityGraph`, { params: request });
  }

  HTTP_AP_GetResidentialPropertyCard(
    request: HTTP_AP_GetResidentialPropertyCard_Request,
  ): Observable<HTTP_AP_GetResidentialPropertyCard_Response> {
    return this.httpClient.get(`/property-service/ap/properties/one/ResidentialPropertyCard`, { params: request });
  }

  HTTP_AP_ListHPIIssues(request?: undefined): Observable<HTTP_AP_ListHPIIssues_Response> {
    return this.httpClient.get(`/property-service/ap/hpi/issues`, { params: request });
  }

  HTTP_AP_ListHPIOutcodeToRegionEntries(
    request: HTTP_AP_ListHPIOutcodeToRegionEntries_Request,
  ): Observable<HTTP_AP_ListHPIOutcodeToRegionEntries_Response> {
    return this.httpClient.get(`/property-service/ap/hpi/outcode-to-region-entries`, { params: request });
  }

  HTTP_AP_ListProperties_ForClientDetailMortgageList(
    request: HTTP_AP_ListProperties_ForClientDetailMortgageList_Request,
  ): Observable<HTTP_AP_ListProperties_ForClientDetailMortgageList_Response> {
    return this.httpClient.get(`/property-service/ap/properties/all/ForClientDetailMortgageList`, { params: request });
  }

  HTTP_AP_ListProperties_ForTable(request: HTTP_AP_ListProperties_ForTable_Request): Observable<HTTP_AP_ListProperties_ForTable_Response> {
    return this.httpClient.get(`/property-service/ap/properties/all/ForTable`, { params: request });
  }

  HTTP_AP_ListPropertyCards(request: HTTP_AP_ListPropertyCards_Request): Observable<HTTP_AP_ListPropertyCards_Response> {
    return this.httpClient.get(`/property-service/ap/properties/all/PropertyCard`, { params: request });
  }

  HTTP_AP_ListPropertyComparables(request: HTTP_AP_ListPropertyComparables_Request): Observable<HTTP_AP_ListPropertyComparables_Response> {
    return this.httpClient.get(`/property-service/ap/properties/one/ListPropertyComparables`, { params: request });
  }

  HTTP_AP_SetPropertyValue(request: HTTP_AP_SetPropertyValue_Request): Observable<undefined> {
    return this.httpClient.post(`/property-service/ap/property/${request.propertyId}/SetValue`, request);
  }

  HTTP_AP_UnsetPropertyValue(request: HTTP_AP_UnsetPropertyValue_Request): Observable<undefined> {
    return this.httpClient.post(`/property-service/ap/property/${request.propertyId}/UnsetValue`, request);
  }

  HTTP_AP_UpdateCompanyPropertySettings(
    request: HTTP_AP_UpdateCompanyPropertySettings_Request,
  ): Observable<HTTP_AP_CompanyPropertySettings> {
    return this.httpClient.put(`/property-service/ap/company-property-settings/${request.companyId}`, request);
  }

  HTTP_AP_UpdateHPIOutcodeToRegionEntry(
    request: HTTP_AP_UpdateHPIOutcodeToRegionEntry_Request,
  ): Observable<HTTP_AP_HPIOutcodeToRegionEntry> {
    return this.httpClient.put(`/property-service/ap/hpi/outcode-to-region-entries`, request);
  }

  HTTP_AP_UpdateProperty_FromClientDetail(request: HTTP_AP_UpdateProperty_FromClientDetail_Request): Observable<undefined> {
    return this.httpClient.put(`/property-service/ap/properties/${request.propertyId}/FromClientDetail`, request);
  }

  HTTP_AP_UpdatePropertyName(request: HTTP_AP_UpdatePropertyName_Request): Observable<HTTP_AP_UpdatePropertyName_Response> {
    return this.httpClient.put(`/property-service/ap/properties/${request.propertyId}/name`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_CloseProperty(request: HTTP_CP_CloseProperty_Request): Observable<undefined> {
    return this.httpClient.post(`/property-service/cp/properties/${request.propertyId}/Close`, request);
  }

  HTTP_CP_CreateProperty(request: HTTP_CP_CreateProperty_Request): Observable<HTTP_CP_Property> {
    return this.httpClient.post(`/property-service/cp/properties`, request);
  }

  HTTP_CP_ListProperties(request?: undefined): Observable<HTTP_CP_ListProperties_Response> {
    return this.httpClient.get(`/property-service/cp/properties`, { params: request });
  }

  HTTP_CP_SetPropertyValue(request: HTTP_CP_SetPropertyValue_Request): Observable<HTTP_CP_SetPropertyValue_Response> {
    return this.httpClient.post(`/property-service/cp/property/${request.propertyId}/SetValue`, request);
  }

  HTTP_CP_UnsetPropertyValue(request: HTTP_CP_UnsetPropertyValue_Request): Observable<HTTP_CP_UnsetPropertyValue_Response> {
    return this.httpClient.post(`/property-service/cp/property/${request.propertyId}/UnsetValue`, request);
  }

  HTTP_CP_UpdateProperty(request: HTTP_CP_UpdateProperty_Request): Observable<HTTP_CP_Property> {
    return this.httpClient.put(`/property-service/cp/properties/id/${request.id}`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // TODO WEB-3272 old endpoint, remove once UI migrated to LandClan
  HTTP_X_FindAddress(request: HTTP_X_FindAddress_Request): Observable<HTTP_X_FindAddress_Response> {
    return this.httpClient.post(`/property-service/x/address/Find`, request);
  }

  HTTP_X_FindAddress_LandClan(request: HTTP_X_FindAddress_LandClan_Request): Observable<HTTP_X_FindAddress_LandClan_Response> {
    return this.httpClient.post(`/property-service/x/address/Find_LandClan`, request);
  }

  // TODO WEB-3272 old endpoint, remove once UI migrated to LandClan
  HTTP_X_RetrieveAddress(request: HTTP_X_RetrieveAddress_Request): Observable<HTTP_X_RetrieveAddress_Response> {
    return this.httpClient.post(`/property-service/x/address/Retrieve`, request);
  }

  HTTP_X_RetrieveAddress_LandClan(request: HTTP_X_RetrieveAddress_LandClan_Request): Observable<HTTP_X_RetrieveAddress_LandClan_Response> {
    return this.httpClient.post(`/property-service/x/address/Retrieve_LandClan`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
